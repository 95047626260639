import cover_segundapessoa from './assets/segundapessoa.jpg';
import cover_metal from './assets/metal.jpg';
import cover_o_resto from './assets/o_resto.jpg';
import cover_doisquartos from './assets/doisquartos.jpg';
import cover_terceirapessoa from './assets/terceirapessoa.jpg';
import cover_primeirapessoa from './assets/primeirapessoa.jpg';
import cover_trespasse from './assets/trespasse.png';
import cover_desmonte from './assets/desmonte.jpg';
import cover_clutching from './assets/clutching.jpg';
import cover_final from './assets/final.jpg';

const releases = [
    {
        name: 'Segunda Pessoa',
        cover: cover_segundapessoa,
        link: 'https://onerpm.link/500061923794',
        release: '2023-12-31'
    },
    {
        name: 'Metal',
        cover: cover_metal,
        link: 'https://onerpm.link/937671899325',
        yt: 'bFPX3R928ow',
        release: '2023-11-29'
    },
    {
        name: 'O Resto',
        cover: cover_o_resto,
        link: 'https://onerpm.link/498786303680',
        yt: 'r3NVhewHnQU',
        release: '2023-10-26'
    },
    {
        name: 'Dois Quartos',
        cover: cover_doisquartos,
        link: 'https://onerpm.link/313973836624',
        yt: 'RTVKXV0tClE',
        release: '2023-10-11'
    },
    {
        name: 'Terceira Pessoa',
        cover: cover_terceirapessoa,
        link: 'https://onerpm.link/957991656613',
        yt: '45v0IelZih8',
        release: '2023-08-24'
    },
    {
        name: 'Primeira Pessoa',
        cover: cover_primeirapessoa,
        link: 'https://onerpm.link/965707728681',
        yt: 'nY0OleYaGwk',
        release: '2023-06-21'
    },
    {
        name: 'Trespasse',
        cover: cover_trespasse,
        link: 'https://onerpm.link/185363683685',
        yt: 'hXwm4lZw5_k',
        release: '2023-05-25'
    },
    {
        name: 'Desmonte',
        cover: cover_desmonte,
        link: 'https://onerpm.link/454200551494',
        yt: 'TAJ3YPIGP-k',
        release: '2023-04-20'
    },
    {
        name: 'Clutching The Edge',
        cover: cover_clutching,
        link: 'https://onerpm.link/393586160230',
        yt: 'B8eNw3ilD8k',
        release: '2023-03-16'
    },
    {
        name: 'Final',
        cover: cover_final,
        link: 'https://onerpm.link/947123305629',
        yt: null,
        release: '2022-04-20'
    }
];

export default releases;