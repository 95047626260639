import './index.css';
import plats from '../../assets/platforms.svg';


function MainRelease(props:any) {

  const nowDate = Date.now();  
  const release = new Date(props.releases[0].release);
  const today = new Date(nowDate);
  const isReleased = today.getTime() >= release.getTime();

  const releaseStringDate = release.toLocaleDateString('pt-BR', { timeZone: 'UTC', day: 'numeric', month: 'long', year: 'numeric' });

  return (
    <div className="MainRelease">     
      <div className='background' style={{ backgroundImage: `url(${props.releases[0].cover})` }}></div>
      <img className='cover' src={props.releases[0].cover} />
      <div className='line'>Novo single</div>
      <div className="title">{props.releases[0].name}</div>
      { !isReleased && <br/> }
      { !isReleased && <div className='line'>{ releaseStringDate }</div> }
      <br/>
      <a href={props.releases[0].link} target="_blank" className='button'>{ isReleased ? 'Ouça agora' : 'Faça o pré-save'}</a>
      <a href={props.releases[0].link} target="_blank" className='presave'>
        <img className='logos' src={plats} />
        <div className='line'>Disponível nas principais plataformas.</div>
      </a>
      {
        isReleased && props.releases[0].yt && 
          <div className='yt_frame'>
            <iframe src={`https://www.youtube.com/embed/${props.releases[0].yt}`} frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
      }

      
    </div>
  );
}

export default MainRelease;
