import './index.css';

function Header() {

  return (
    <div className="Header">      
      <div className="title">ossamenta</div>
      <div className='divider'></div>
    </div>
  );
}

export default Header;
