import './index.css';

function Footer() {
  return (
    <div className="Footer">      
      <div className='links'>
        <a className="link" target="blank" href='https://www.youtube.com/channel/UCsmGGBJasr_0IMuszHxcDyg'>
          YOUTUBE
        </a>
        <a className="link" target="blank" href='https://www.instagram.com/_ossamenta/'>
          INSTAGRAM
        </a>
      </div>
    </div>
  );
}

export default Footer;
