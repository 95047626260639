import './App.css';
import Footer from './components/footer';
import Album2 from './components/album2';
import AlbumPresave from './components/album-presave';
import Header from './components/header';
import MainRelease from './components/main-release';
import OtherReleases from './components/other-releases';
import releases from './releases';

function App() {

  return (
    <div className="App">     
      <Header/> 
      <MainRelease releases={releases}/>
      <div className='divider'></div>
      <OtherReleases releases={releases}/>
      <div className='divider'></div>
      <Footer/>
    </div>
  );
}

export default App;
