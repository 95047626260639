import './index.css';

function OtherReleases(props:any) {
  return (
    <div className="OtherReleases">
      <div className='title'>outros lançamentos</div>
      <div className='releases'>
        { props.releases.map((r:any, i:number) => (
          i > 0 && 
          <a target='_blank' href={r.link} key={i} className='release'> 
            <img src={r.cover}/>
            <div className='name'>{r.name}</div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default OtherReleases;
